import { axios, API_ROUTES } from "@/config";

export default {
  async save(data) {
    let result = await axios.post(`${API_ROUTES.transaction.save}`, data);

    return result;
  },
  async update(data) {
    let result = await axios.put(`${API_ROUTES.transaction.update}`, data);

    return result;
  },
};
